import axios from '@/http/axios'
import {createCancelTokenHandler} from "../../axios/utils";

const cancelTokenHandlerObject = {
    getTimePrices: createCancelTokenHandler('getTimePrices')
}


export function getTimePrices (page, filters = [], sorts = []) {
    let queries = ''
    if (page > 1) {
        queries += `page=${page}`
    }

    if (filters.length) {
        if (queries.length > 0) queries += '&'

        queries += filters.join('&')
    }

    if (sorts.length) {
        if (queries.length > 0) queries += '&'

        queries += sorts.join('&')
    }

    if (queries.length > 0) queries = `?${  queries}`

    return axios({
        url: `v1/admin/club/time-prices${queries}`,
        method: 'get',
        // setting a cancel token and canceling the previous request of this type
        cancelToken: cancelTokenHandlerObject['getTimePrices'].handleRequestCancellation().token
    })
}



export function editTimePrices (payment) {
    return axios({
        url: 'v1/admin/club/time-prices',
        data: payment,
        method: 'patch'
    })
}
