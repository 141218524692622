<template>
  <div class="edit-term-prices">

    <draggable-dynamic-table ref="editTermsPriceTable"
                             :in-modal="true"
                             :active-grid="true"
                             :columns="columnsLabel"
                             :options="options"
                             @column:select="consoleLog($event)"
                             v-model="data"/>


    <button id="editTermPriceBTN" v-show="false" @click="sendData"/>
  </div>
</template>

<script>
import axios from 'axios'
import DraggableDynamicTable from '@/components/draggableDynamicTable/draggableDynamicTable'
import {getTimePrices, editTimePrices} from '@/http/requests/club/timePrices'
import { addComma } from '../../../../../../assets/js/functions'

export default {
  name: 'EditTermPrice',
  metaInfo () {
    return {
      title: this.$t('terms.price.edit.title')
    }
  },
  components: {
    DraggableDynamicTable
  },
  props: {
    termId: {
      type: [Number, String],
      default: 0
    }
  },
  data () {
    return {
      loadingTimer: 0,
      options: {
        id: 'editTermsPriceTable',
        rowKeyField: 'id'
      },
      columnsLabel: [
        {
          field: 'day_7',
          i18n: 'terms.price.table.header.friday',
          width: 'calc((100% / 10))',
          minWidth: 120,
          editable: true,
          returnValue: false,
          valueType: 'price',
        },
        {
          field: 'day_6',
          i18n: 'terms.price.table.header.thursday',
          width: 'calc((100% / 10))',
          minWidth: 120,
          editable: true,
          returnValue: false,
          valueType: 'price',
        },
        {
          field: 'day_5',
          i18n: 'terms.price.table.header.wednesday',
          width: 'calc((100% / 10))',
          minWidth: 120,
          editable: true,
          returnValue: false,
          valueType: 'price',
        },
        {
          field: 'day_4',
          i18n: 'terms.price.table.header.tuesday',
          width: 'calc((100% / 10))',
          minWidth: 120,
          editable: true,
          returnValue: false,
          valueType: 'price',
        },
        {
          field: 'day_3',
          i18n: 'terms.price.table.header.monday',
          width: 'calc((100% / 10))',
          minWidth: 120,
          editable: true,
          returnValue: false,
          valueType: 'price',
        },
        {
          field: 'day_2',
          i18n: 'terms.price.table.header.sunday',
          width: 'calc((100% / 10))',
          minWidth: 120,
          editable: true,
          returnValue: false,
          valueType: 'price',
        },
        {
          field: 'day_1',
          i18n: 'terms.price.table.header.saturday',
          width: 'calc((100% / 10))',
          minWidth: 120,
          editable: true,
          returnValue: false,
          valueType: 'price',
        },
        {
          field: 'courtName',
          i18n: 'terms.price.table.header.courtName',
          width: '150px',
          minWidth: 150,
          align: 'center',
          // locked: true,
          // sortable: true,
          // footer: {
            /*type: 'auto-counter'*/
          // }
        }
      ],
      data: [],
      filters: [`term=${this.termId || this.$route.params.id}`],
      sorts: ['order[0]=court_id,asc'],
      page: 1,
      endedList: false,
      actions: [
        {
          toolbar: [
            {
              id: 'editTermPriceBTN',
              icon: 'SAVE',
              iconPack: 'useral',
              color: 'success',
              permission: 'term.update'
            }
          ],
          leftToolbar: [
            {
              id: 'printTable',
              // i18n: 'draggableDynamicTable.actions.print',
              icon: 'PRINT',
              iconPack: 'useral'
            },
            {
              id: 'downloadTable',
              // i18n: 'draggableDynamicTable.actions.download',
              icon: 'DOWNLOAD',
              iconPack: 'useral'
            },
            {
              id: 'settingTable',
              icon: 'icon-settings',
              iconPack: 'feather'
            }
          ]
        }
      ]
    }
  },
  created () {

    setTimeout(() => {
      // this.$store.dispatch('updateNavbarActions', this.actions[0])
      this.$store.dispatch('updateContentNavbarStyle', 'sticky')
    }, 50)

    this.getTerms()
  },
  methods: {
    getTerms () {
      clearTimeout(this.loadingTimer)
      this.loadingTimer = setTimeout(() => {
        if (!this.endedList) {
          if (this.$refs.editTermsPriceTable && this.data.length === 0) {
            this.$refs.editTermsPriceTable.loadMoreStatus = 'FirstLoad'
          } else if (this.$refs.editTermsPriceTable && this.data.length > 0) {
            this.$refs.editTermsPriceTable.loadMoreStatus = 'Loading'
          }

          getTimePrices(this.page, this.filters, this.sorts).then((res) => {
            this.endedList = res.data.data.length === 0
            let data = {}
            res.data.data.forEach((term) => {
              if (term.court) {
                if (!data[`court_${term.court.id}`]) {
                  data[`court_${term.court.id}`] = {}
                }
                data[`court_${term.court.id}`]['courtName'] = term.court.name || ''
                data[`court_${term.court.id}`]['courtId'] = term.court.id
                data[`court_${term.court.id}`]['flag'] = 'NEW'
                data[`court_${term.court.id}`][`day_${term.week_day}`] = addComma(term.price)
              }
            })
            const dataArray = Object.values(data)
            setTimeout(() => {
              dataArray.forEach((item) => {
                this.data.push(item)
              })
            }, 100)

            // this.page = res.data.pagination.current_page + 1
            // if (res.data.pagination.current_page === 1) {
            // const row_index = this.columnsLabel.map((e) => {
            //   return e.field
            // }).indexOf('row')
            // this.columnsLabel[row_index].footer.value = res.data.pagination.total
            // }

            if (this.$refs.editTermsPriceTable) this.$refs.editTermsPriceTable.loadMoreStatus = ''
          })
              .catch((error) => {
                if (this.$refs.editTermsPriceTable && !axios.isCancel(error)) this.$refs.editTermsPriceTable.loadMoreStatus = 'Danger'
              })
        }
      }, 400)
    },

    sendData () {
      let payload = []

      this.data.forEach((court) => {
        if (court.flag === 'UPDATED') {
          for (let i = 1; i <= 7; i++) {
            payload.push({
              week_day: i,
              term_id: this.termId || this.$route.params.id,
              court_id: court.courtId,
              price: court[`day_${i}`] ? court[`day_${i}`].replaceAll(',', '') : '0'
            })
          }
        }
      })

      if (payload.length > 0) {
        editTimePrices({prices: payload}).then((response) => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('terms.price.notifications.edit.success'),
            icon: 'icon-check',
            iconPack: 'feather',
            time: 2400,
            color: 'success'
          })
          this.$emit('insert')

        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            const error_mapper = {
              'name': '',
            }

            switch (error.response.status) {
              case 422:
                Object.keys(error.response.data.errors).forEach((error_key) => {
                  const err = error_key.toString().split('.')
                  if (error_mapper[err[err.length - 1]]) {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: error_mapper[err[err.length - 1]],
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 2400
                    })
                    error_mapper[err[err.length - 1]] = null
                  }
                })
                break

              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('terms.price.notifications.edit.error'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          }
        })
      }
    },

    consoleLog () {
      // console.log(event)
    },
    handleClick (id) {
      document.getElementById(id).click()
    }
  }
}
</script>

<style lang="scss" scoped>
  .edit-term-prices {
    height: 100%;
  }
</style>
